import React from 'react';
import Theme from '../../../../theme';
import { Grid, Button, Hidden } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import useStyles from './Steps.styles';
import StepsFigures from '../../../../../content/assets/icons/Enterprise/StepsFigures.svg';
import StepsFiguresResponsive from '../../../../images/stepsFiguresResponsive.svg';

const Steps = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      {
        <Grid item xs={12} sm={12} md={12} className={classes.div}>
          <Container className={classes.container} maxWidth="lg">
            <Grid item xs={12}>
              <h2 className={classes.title}>
                Registra{' '}
                <Hidden smUp>
                  <br></br>
                </Hidden>
                tu empresa en <b>10 minutos</b>
              </h2>
            </Grid>
            <Grid item container xs={12} className={classes.groupCircles}>
              <Grid item xs={12}>
                <img className={classes.stepsFigures} src={StepsFigures} alt="StepsFigures" />
              </Grid>
            </Grid>
            <Grid item xs={3} className={classes.groupCirclesResponsive}>
              <img src={StepsFiguresResponsive} alt="StepsFiguresResponsive" />
            </Grid>
            <Grid item xs={9} sm={9} md={12} className={classes.group}>
              <Grid id="box1" item xs={12} sm={12} md={3} className={classes.box}>
                <h3 className={classes.h3}>1. Regístrate en CurrencyBird</h3>
                <p className={classes.paragraphs}>
                  Si eres el representante de la empresa, primero debes registrarte como persona
                  natural.
                </p>
              </Grid>
              <Grid id="box2" item xs={12} sm={12} md={6} className={classes.box}>
                <h3 className={classes.h3}>2. Registra tu empresa</h3>
                <p className={classes.paragraphs}>
                  Si ya eres usuario de CurrencyBird, ingresa a tu cuenta y registra tu empresa.
                </p>
              </Grid>
              <Grid id="box3" item xs={12} sm={12} md={3} className={classes.box}>
                <h3 className={classes.h3}>3. Crea tu destinatario</h3>
                <p className={classes.paragraphs}>
                  Ingresa los datos de la persona o empresa a quien quieres enviar el dinero.
                </p>
              </Grid>
              <Grid item xs={12} sm={12} md={12} className={classes.helpSection}>
                <Grid item xs={4} sm={4} md={4}>
                  <Button
                    href={`${process.env.GATSBY_URL_NEW_FRONT}/login?type=company`}
                    className={classes.transferButton}
                    onClick={(e) => {
                      window.dataLayer = window.dataLayer || [];
                      window.dataLayer.push({
                        event: 'buttonClick',
                        category: 'Empresas',
                        action: 'click_registrate',
                        label: 'Registra tu empresa, click en boton registrate',
                      });
                    }}
                  >
                    ¡Regístrate!
                  </Button>
                </Grid>
                <Grid item xs={8} sm={12} md={8} className={classes.help}>
                  <p>
                    Si necesitas ayuda con el registro, agenda una reunión{' '}
                    <a
                      href="https://help.currencybird.cl/meetings/empresas/atencion-empresas"
                      target="_blank"
                    >
                      aquí.
                    </a>
                  </p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.downSectionResponsive}>
              <Button
                href={`${process.env.GATSBY_URL_NEW_FRONT}/login?type=company`}
                className={classes.transferButtonResponsive}
                onClick={(e) => {
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                    event: 'buttonClick',
                    category: 'Empresas',
                    action: 'click_transfiere',
                    label: 'Registra tu empresa, click en boton transfiere',
                  });
                }}
              >
                ¡Regístrate!
              </Button>
              <Grid item xs={12} sm={12} md={8} className={classes.helpResponsive}>
                <p>
                  Si necesitas ayuda con el registro, agenda una reunión{' '}
                  <a
                    href="https://help.currencybird.cl/meetings/empresas/atencion-empresas"
                    target="_blank"
                  >
                    aquí.
                  </a>
                </p>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      }
    </React.Fragment>
  );
};

export default Steps;
