import { Container, Grid, Hidden, Button } from '@material-ui/core';
import React from 'react';
import useStyles from './Pay.styles';
import IconCheck from '../../../../../content/assets/icons/Pay/Check.svg';

const Pay = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <section className={classes.root} id="tecnologías">
        <Container className={classes.container} maxWidth="lg">
          <Grid item xs={12}>
            <h3 className={classes.title}>Paga:</h3>
          </Grid>
          <Grid container className={classes.gridItemsContainer}>
            <Grid item xs={2} md={1}>
              <img src={IconCheck} />
            </Grid>
            <Grid item xs={10} md={3}>
              <p id="paragraph1">
                <strong>A más de 70 países </strong> a un precio conveniente
              </p>
            </Grid>
            <Grid item xs={2} md={1}>
              <img src={IconCheck} />
            </Grid>
            <Grid item xs={10} md={3}>
              <p id="paragraph2">Tus importanciones o comisiones por exportaciones</p>
            </Grid>
            <Grid item xs={2} md={1}>
              <img src={IconCheck} />
            </Grid>
            <Grid item xs={10} md={3}>
              <p id="paragraph3">A tus colaboradores y proveedores en el extranjero</p>
            </Grid>
            <Grid item xs={2} md={1}>
              <img src={IconCheck} />
            </Grid>
            <Grid item xs={10} md={3}>
              <p id="paragraph4">
                <strong>Servicios</strong> prestados
                <Hidden smUp>
                  <br></br>
                </Hidden>{' '}
                en el exterior
              </p>
            </Grid>
            <Grid item xs={2} md={1}>
              <img src={IconCheck} />
            </Grid>
            <Grid item xs={10} md={3}>
              <p id="paragraph5">
                <strong>Comisiones por servicios</strong> prestados en Chile por empresas
                extranjeras
              </p>
            </Grid>
            <Grid item xs={12} md={4}>
              <h3 className={classes.message}>¡Y lo que tu empresa necesite!</h3>
            </Grid>
            <Hidden smUp>
              <Grid className={classes.transferGroup} item xs={12}>
                <Button className={classes.transferButton}>Registra tu empresa</Button>
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Pay;
