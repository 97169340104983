import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import IconQuestion from '../../../../../content/assets/icons/Enterprise/IconQuestion.svg';
import IconQuestionResponsive from '../../../../../content/assets/icons/Enterprise/IconQuestionResponsive.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      ...theme.typography.button,
      padding: theme.spacing(1),
      minHeight: '100vh',
      height: `100%`,
    },
    container: {
      [theme.breakpoints.down(500)]: {
        paddingLeft: `10%`,
        paddingRight: `10%`,
      },
      [theme.breakpoints.up(501)]: {
        paddingLeft: `6%`,
        paddingRight: `6%`,
        margin: `auto`,
      },
      [theme.breakpoints.down(769)]: {
        height: 'auto',
      },
      [theme.breakpoints.up(1921)]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
      paddingTop: 110,
      paddingBottom: 120,
      height: 840,
    },
    div: {
      backgroundColor: `#004F71`,
      [theme.breakpoints.down(1364)]: {
        height: 850,
      },
      [theme.breakpoints.down(900)]: {
        height: 910,
      },
      [theme.breakpoints.down(769)]: {
        height: 1200,
      },
      [theme.breakpoints.down(500)]: {
        height: 1050,
      },
    },
    title: {
      margin: 0,
      fontSize: 48,
      textAlign: `center`,
      color: `#fff`,
      lineHeight: `73px`,
      padding: 0,
      marginBottom: 65,
      [theme.breakpoints.down(1364)]: {
        fontSize: 44,
        marginBottom: 20,
        padding: `0 5% 0`,
      },
      [theme.breakpoints.down(769)]: {
        fontSize: 50,
        lineHeight: `58px`,
      },
      [theme.breakpoints.down(501)]: {
        fontSize: 30,
        lineHeight: `37px`,
        padding: `0 7%`,
      },
      fontWeight: 300,
    },
    h4: {
      margin: 0,
      fontSize: 30,
      textAlign: `center`,
      color: `#fff`,
      fontFamily: `Montserrat`,
      fontWeight: `normal`,
      paddingTop: 15,
      [theme.breakpoints.down(1364)]: {
        fontSize: 20,
      },
      [theme.breakpoints.down('md')]: {
        padding: 20,
      },
    },
    h3: {
      margin: 0,
      fontSize: 26,
      lineHeight: `29px`,
      textAlign: `left`,
      color: `#fff`,
      fontFamily: `Montserrat`,
      fontWeight: `bold`,
      [theme.breakpoints.down('md')]: {
        fontSize: 22,
      },
    },
    box: {
      float: `left`,
      height: 'auto',
      display: `block`,
      textAlign: `left`,
      backgroundColor: `transparent`,
    },
    paragraphs: {
      display: `table-cell`,
      verticalAlign: `middle`,
      lineHeight: `28px`,
      fontSize: 20,
      textAlign: `left`,
      paddingTop: `15px`,
      color: `#fff`,
      // '&::after': {
      //   content: `url(${IconQuestion})`,
      //   width:`20px`,
      //   marginLeft:10,
      //   position:`absolute`,
      //   [theme.breakpoints.down(501)]: {
      //     content: `url(${IconQuestionResponsive})`,
      //   },
      // },
      [theme.breakpoints.down(501)]: {
        fontSize: 16,
        lineHeight: `19px`,
      },
    },
    groupCircles: {
      width: `100%`,
      height: 100,
      [theme.breakpoints.down(769)]: {
        display: `none`,
      },
    },
    group: {
      float: `left`,
      height: 400,
      width: `100%`,
      display: `block`,
      paddingLeft: '6%',
      paddingRight: '6%',
      [theme.breakpoints.down(1000)]: {
        paddingLeft: '3%',
        paddingRight: '3%',
      },
      [theme.breakpoints.down(769)]: {
        height: `auto`,
        paddingLeft: 0,
        marginTop: `10%`,
      },
      '& #box1': {
        paddingLeft: `3%`,
        paddingRight: `1%`,
        [theme.breakpoints.down(1000)]: {
          paddingLeft: `3%`,
          paddingRight: `0%`,
        },
        [theme.breakpoints.down(769)]: {
          height: 240,
          width: `100%`,
        },
      },
      '& #box2': {
        paddingLeft: `13%`,
        paddingRight: `14%`,
        [theme.breakpoints.down(769)]: {
          paddingLeft: `3%`,
          paddingRight: `4%`,
          height: 240,
          width: `100%`,
        },
        [theme.breakpoints.down(501)]: {
          height: 220,
        },
      },
      '& #box3': {
        paddingLeft: `3%`,
        [theme.breakpoints.down(769)]: {
          height: 130,
          width: `100%`,
        },
        [theme.breakpoints.down(501)]: {
          height: 100,
        },
      },
    },
    boxCircle: {
      '& #circle1': {
        float: `right`,
        top: 60,
      },
      '& #circle2': {
        background: `#cfd3d6`,
        '& div': {
          background: `#fff`,
        },
      },
      '& #circle3': {
        background: `#F8B133`,
        '& div': {
          background: `#fff`,
        },
      },
    },
    stepsFigures: {
      width: `100%`,
      padding: `0 12% 0 12%`,
    },
    message: {
      margin: 0,
      fontSize: 24,
      textAlign: `left`,
      marginTop: 30,
      marginBottom: 35,
      lineHeight: 1.2,
      color: `#fff`,
      fontFamily: `Montserrat`,
      fontWeight: `bold`,
      [theme.breakpoints.down('md')]: {
        fontSize: 20,
      },
    },
    transferButtonResponsive: {
      background: '#FAB234',
      color: '#074F71',
      borderRadius: '40px',
      width: '90%',
      textTransform: `initial`,
      fontFamily: `Montserrat`,
      fontWeight: `bold`,
      fontSize: 22,
      marginTop: '45px',
      [theme.breakpoints.down(501)]: {
        marginTop: '60px',
      },
    },
    transferButton: {
      background: '#FAB234',
      color: '#074F71',
      borderRadius: '40px',
      width: 214,
      height: 55,
      textTransform: `initial`,
      fontFamily: `Montserrat`,
      fontWeight: `bold`,
      fontSize: 22,
      '&:hover': {
        background: '#fff',
        color: `#FAB234`,
        border: '2px solid #FAB234',
      },
      [theme.breakpoints.down(1000)]: {
        fontSize: 12,
      },
      [theme.breakpoints.down(769)]: {
        display: `none`,
      },
    },
    groupCirclesResponsive: {
      width: `100%`,
      display: `none`,
      float: `left`,
      [theme.breakpoints.down(769)]: {
        display: `block`,
        width: `100%`,
        paddingRight: `2%`,
        paddingTop: `10%`,
        '& img': {
          float: `right`,
        },
      },
    },
    downSectionResponsive: {
      [theme.breakpoints.down(769)]: {
        padding: `0 9% 0`,
        display: `block`,
      },
      [theme.breakpoints.up(769)]: {
        display: 'none',
      },
      '& a': {
        color: '#074F71',
        borderRadius: '40px',
        width: `100%`,
        height: 53,
        textTransform: `inherit`,
        fontWeight: `bold`,
        '&:hover': {
          background: '#fff',
          color: `#FAB234`,
          border: '2px solid #FAB234',
        },
        [theme.breakpoints.down(769)]: {
          width: `100%`,
          color: `#074F71`,
        },
      },
      '& h3': {
        margin: 0,
        fontSize: 25,
        textAlign: `center`,
        marginTop: 20,
        marginBottom: 35,
        lineHeight: 1.2,
        color: `#fff`,
        fontFamily: `Montserrat`,
        fontWeight: `normal`,
      },
    },
    Link: {
      color: `#fff`,
    },
    help: {
      color: `#fff`,
      fontSize: '16px',
      paddingLeft: '30px',
      fontWeight: 500,
      '& a': {
        color: `#fff`,
      },
      [theme.breakpoints.down(769)]: {
        display: 'none',
      },
    },
    helpResponsive: {
      color: `#fff`,
      fontSize: '16px',
      paddingLeft: '30px',
      fontWeight: 500,
      textAlign: 'center',
      [theme.breakpoints.down(769)]: {
        padding: 0,
      },
      '& a': {
        color: `#fff`,
      },
      '& p': {
        marginTop: '30px',
        [theme.breakpoints.down(500)]: {
          fontSize: '12px',
        },
      },
    },
    meetingSection: {
      display: 'inline-block',
      marginLeft: '100px',
      '& p': {
        marginTop: 0,
      },
    },
    helpSection: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '2.5%',
      width: '100%',
      height: '80px',
      paddingTop: '80px',
      verticalAlign: 'middle',
      [theme.breakpoints.down(769)]: {
        padding: 0,
        display: 'none',
      },
    },
  })
);

export default useStyles;
